import React from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const RegulaminPostoju = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Regulamin korzystania z miejsc postojowych"
      lang={lang}
    >
      <SmallBanner title="Regulamin korzystania z miejsc postojowych" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <h3>
              REGULAMIN KORZYSTANIA Z MIEJSC POSTOJOWYCH <br /> na terenie zakładu Palettenwerk Kozik Sp. z o.o. w Jordanowie

              </h3>
              <p>1. Przepisy ogólne</p>
              <ul>
                <li>
                    Regulamin określa szczegółowe warunki korzystania z miejsc postojowych znajdujących się na terenie Palettenwerk Kozik Sp. z o.o., mieszczących się pod adresem: ul. Przemysłowa 219, 34-240 Jordanów.
                </li>
                <li>
                    Każdy kierowca poprzez wjazd pojazdem na teren zakładu akceptuje zapisy niniejszego Regulaminu i zobowiązuje się do ścisłego przestrzegania jego postanowień. 
                </li>
                <li>
                    Zarządzający nie odpowiada za utratę lub uszkodzenie pojazdu, ani też za jakiekolwiek inne szkody, jeżeli zdarzenia, o których mowa powstały w szczególności na skutek pozostawienia otwartych drzwi, okien lub  bagażnika, zagubienia kluczyków lub pozostawienia ich wewnątrz pojazdu, za powstanie pożaru w wyniku samozapłonu pojazdu, za pozostawienie pojazdu z zapalonym silnikiem, udostępnieniem pojazdu osobie nieuprawnionej do kierowania pojazdami samochodowymi.
                </li>
                <li>
                    Zarządzający nie ponosi odpowiedzialności za pozostawione w pojeździe kosztowności, wartościowe przedmioty kierowcy pojazdu.
                </li>
                <li>
                    Parking przeznaczony jest dla wyłączne dla pojazdów dokonujących na terenie zakładu załadunku/rozładunku.
                </li>
                <li>
                    Na terenie parkingu obowiązuje ograaniczenie prędkości poruszania się pojazdem do 10 km/h.
                </li>
              </ul>
              <p>2. Warunki korzystania z parkingu</p>
              <ul>
                <li>
                    W czasie parkowania kierujący pojazdem musi uważać na pojazdy sąsiednie. 
                </li>
                <li>
                  Przewoźnik obowiązany jest bezzwłocznie okazać, na każde
                  żądanie Zleceniodawcy polisę potwierdzającą zawarcie umowy
                  ubezpieczenia odpowiedzialności cywilnej, a także certyfikaty,
                  licencje i inne dokumenty związane z przewozem. Zleceniodawcy
                  przysługuje prawo sporządzenia kopii z okazanych dokumentów.
                </li>
                <li>
                    Na terenie parkingu zabronione jest spożywanie alkoholu.
                </li>
                <li>
                    Kierowca odpowiada za wszystkie szkody spowodowane przez niego na terenie parkingu.
                </li>
                <li>
                    Dla kierowców przybywających na teren zakładu dokonujących rozładunku/załadunku parking jest darmowy do czasu załadunku/rozładunku oraz do 30 minut po dokonaniu w/w czynności.
                </li>
                <li>
                    Po upływie czasu określonego w §2 ust. 4 zostanie naliczona opłata w wysokości 200 zł za każdą rozpoczętą dobę postoju pojazdu. Informacja o opłacie będzie wręczana kierowcy.
                </li>
                <li>
                    Opłata zostanie naliczona poprzez wystawienie faktury VAT, którą właściciel parkingu – Spółka Palettenwerk prześle pocztą na adres firmy transportowej dokonującej załadunku/rozładunku.
                </li>
                <li>
                    Regulamin dostępny jest pod adresem: <br />
                    <a href="www.palettenwerk.pl/regulamin_postoju/"> www.palettenwerk.pl/regulamin_postoju/</a> 
                </li>
                <li>
                    W przypadkach nie ujętych w niniejszym Regulaminie mają zastosowanie przepisy Kodeksu Cywilnego.
                </li>
                </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RegulaminPostoju;
